import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonNote,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { podiumOutline } from "ionicons/icons";
import { useParams } from "react-router";
import "../theme/styles.css";

const Dashboard: React.FC = () => {
  const { name } = useParams<{ name: string }>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen class="ion-padding app-bg">
      <IonGrid>
          <IonRow>
            <IonCol size="6" size-lg="3">
                <div className="rounded-container">
                <h1>0</h1>
                    <p>Total Investors</p>
                </div>
                    
            </IonCol>
            <IonCol size="6" size-lg="3">
                <div className="rounded-container">
                <h1>0</h1>
                    <p>Total AUM</p>
                </div>
            </IonCol>
            <IonCol size="6" size-lg="3">
                <div className="rounded-container">
                <h1>0</h1>
                    <p>Monthly SIP</p>
                </div>
                    
            </IonCol>
            <IonCol size="6" size-lg="3">
                <div className="rounded-container">
                <h1>0</h1>
                    <p>Total Lumpsum</p>
                </div>
                    
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
