interface AlertProps {
  alert?: any;
}
const Alert: React.FC<AlertProps> = ({ alert }) => {

  if (alert.type === "error") {
    return <div className="alert-danger text-xs">{alert.message}</div>;
  } else if (alert.type === "success") {
    return <div className="alert-success text-xs">{alert.message}</div>;
  } else {
    return <></>;
  }
};
export default Alert;
