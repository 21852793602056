import * as actions from "./actions";
import API from "../../api";

export const login = (data: any) => {
  return {
    type: actions.LOGIN,
    data: data,
  };
};

export const userLogin = (userData: any) => {
  return function (dispatch: any) {
    dispatch(login(userData));
  };
};

export const storeUser = (data: any) => {
  return {
    type: actions.STORE_PROFILE,
    data: data,
  };
};

export const getUserProfile = (id: any) => {
  return function (dispatch: any) {
    API.getUser(id)
      .then((result) => {
        dispatch(storeUser(result.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
