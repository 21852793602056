import * as actions from "./actions";
import API from "../../api";

export const storeAllInvestors = (data: any) => {
  return {
    type: actions.STORE_ALL_INVESTORS,
    data: data,
  };
};

export const getAllInvestors = () => {
  return function (dispatch: any) {
    API.getAllInvestors()
      .then((result) => {
        dispatch(storeAllInvestors(result.data.data));
      })
      .catch((error) => {
        console.log("Failed to retrieve investor details");
      });
  };
};
