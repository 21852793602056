import React from "react";
import { IonRouterOutlet, IonSplitPane } from "@ionic/react";

import { Redirect, Route } from "react-router-dom";
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from "@ionic/react";
import {
  gridOutline,
  podiumOutline,
  personCircleOutline,
} from "ionicons/icons";


/* App Tabs */
//import Account from "../pages/Account";
//import Investments from "../pages/Investments";
import Menu from "./Menu";
import Dashboard from "../pages/Dashboard";
import Exload from "../pages/Exload";
import Investors from "../pages/Investors";
import Investor from "../pages/Investor";
import Aum from "../pages/Aum";
import SIPTracker from "../pages/SIPTracker";
import Applications from "../pages/Applications";
import Application from "../pages/Application";
import Transact from "../pages/Transact";

const AppRouter: React.FC = () => {

  return (
    <IonSplitPane contentId="main">
    <Menu />
    <IonRouterOutlet id="main">
      <Route path="/" exact={true}>
        <Redirect to="/dashboard" />
      </Route>
      <Route path="/dashboard" exact={true}>
        <Dashboard />
      </Route>
      <Route path="/applications" exact={true}>
        <Applications/>
      </Route>
      <Route path="/application/:id" exact={true}>
        <Application/>
      </Route>
      <Route path="/siptracker" exact={true}>
        <SIPTracker/>
      </Route>
      <Route path="/exload" exact={true}>
        <Exload />
      </Route>
      <Route path="/aum" exact={true}>
        <Aum />
      </Route>
      <Route path={"/transact"} exact={true}>
        <Transact/>
      </Route>
      <Route path="/investors" exact={true}>
        <Investors />
      </Route>
     
      <Route path="/investor/:id" exact={true}>
        <Investor />
      </Route>
    </IonRouterOutlet>
  </IonSplitPane>
  );
};
export default AppRouter;
