import * as actions from "../actions/actions";

const initialState = {
  ucc: "",
  name: "",
  email: "",
  token: "",
  refresh_token: "",
  scope: "",
  expiry: "",
};

const userLogin = (state: any, action: any) => {
  return {
    ...state,
    token: action.data.access_token,
    refresh_token: action.data.refresh_token,
    scope: action.data.scope,
    expiry: action.data.expires_in,
  };
};

const userProfile = (state: any, action: any) => {
  return {
    ...state,
    ucc: action.data.profile.uccId,
    name: action.data.firstName,
    email: action.data.email,
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.LOGIN:
      return userLogin(state, action);
    case actions.STORE_PROFILE:
      return userProfile(state, action);
    default:
      return state;
  }
};

export default reducer;
