import {
  IonItem,
  IonCol,
  IonAvatar,
  IonLabel,
  IonText,
  IonSkeletonText,
} from "@ionic/react";
interface WidgetProps {
  id?: string;
  name?: string;
  pan?: string;
  email?: string;
  loading?: boolean;
}
const InvestorCard: React.FC<WidgetProps> = ({
  id,
  name,
  pan,
  email,
  loading,
}) => {
  if (loading) {
    return (
      <IonCol>
        <div className="rounded-container">
          <IonItem className="investor-card" lines="none">
            <IonAvatar slot="start">
              <IonSkeletonText
                animated
                style={{ width: "50px", height: "50px" }}
              />
            </IonAvatar>
            <IonLabel>
              <IonText color="primary">
                <IonSkeletonText
                  animated
                  style={{ width: "150px", height: "27px" }}
                />
              </IonText>
              <p>
                <br />
                <IonSkeletonText animated style={{ width: "50%" }} />
                <IonSkeletonText animated style={{ width: "70%" }} />
                <IonSkeletonText animated style={{ width: "80%" }} />
              </p>
            </IonLabel>
          </IonItem>
        </div>
      </IonCol>
    );
  } else {
    return (
      <IonCol>
        <div className="rounded-container">
          <IonItem
            className="investor-card"
            lines="none"
            routerLink={"/investor/" + id}
          >
            <IonAvatar slot="start">
              <img src="assets/images/user.png" />
            </IonAvatar>
            <IonLabel>
              <IonText color="primary">{name}</IonText>
              <br />
              <p>
                <br />
                Kashly ID : {id}
                <br />
                PAN: {pan}
                <br />
                {email}
              </p>
            </IonLabel>
          </IonItem>
        </div>
      </IonCol>
    );
  }
};
export default InvestorCard;
