import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router";
import { IonLoading } from "@ionic/react";
import { UserContext } from "../App";

const Logout: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const user = useContext(UserContext);

  useEffect(() => {
    setShowLoading(true);
  }, []);

  setTimeout(() => {
    localStorage.removeItem("token");
    setShowLoading(false);
    user.setIsLoggedIn(false);
  }, 1000);

  return (
    <IonLoading
      isOpen={showLoading}
      onDidDismiss={() => setShowLoading(false)}
      message={"Signing out..."}
    />
  );
};
export default Logout;
