import { formatScheme, currencyFormat } from "../Utilities";

export const dueSipHeader = [
  "Folio Number",
  "Scheme",
  "Investor",
  "Amount",
  "SIP Date",
  "Status",
];

export const cancelledSIPHeader = [
  "AMC",
  "Scheme",
  "Investor",
  "Amount",
  "Cancelled",
  "Trigger",
  "Actions",
];

export const pendingSIPHeader = [
  "AMC",
  "Scheme",
  "Investor",
  "Amount",
  "Registered",
  "RTA",
];

export const getCancelledEntry = (cancelDetail: any) => {
  return (
    <>
      <h5>
        <b>Cancellation Details</b>
      </h5>
      <br />
      <br />
      <span className="text-s">
        <b>Scheme Details</b>
      </span>
      <br />
      {formatScheme(cancelDetail.schemeName)} ({cancelDetail.rta})
      <br />
      <span className="text-xs">
        Folio: {cancelDetail.folio_number} | SIP Amount:{" "}
        {currencyFormat(cancelDetail.amount)}
      </span>
      <br /><br/>
      <span className="text-s">
        <b>SIP Details</b>
      </span><br/>
      <span className="text-xs">
        Registered on: {cancelDetail.regDate.substr(0,10)} | Trigger day: {cancelDetail.trigger}
      </span>
      <br />
      <br />
      <span className="text-s">
        <b>Cancelled On</b>
      </span>
      <br />
      {cancelDetail.cancelDate.substr(0, 10)}
      <br />
      <br />
      <span className="text-s">
        <b>Bank Details</b>
      </span>
      <br />
      {cancelDetail.bank}
      <br />
      <br />
      <span className="text-s">
        <b>Reason</b>
      </span>
      <br />
      {cancelDetail.remarks}
      <br />
    </>
  );
};
