import { IonItem, IonCard, IonCardContent, IonCardTitle } from "@ionic/react";
interface WidgetProps {
  title?: string;
  data?: any;
}
const Widget: React.FC<WidgetProps> = ({ title, data }) => {
  return (
    <IonCard className="widget">
      <IonCardContent>{data}</IonCardContent>
    </IonCard>
  );
};
export default Widget;
