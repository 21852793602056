import * as actions from "../actions/actions";

const initialState = {
  dues: null,
  cancelled: null,
  pending: null,
};

const storeSIPDues = (state: any, action: any) => {
  return {
    ...state,
    dues: action.data,
  };
};

const storeCancelledSIPs = (state: any, action: any) => {
  return {
    ...state,
    cancelled: action.data,
  };
};

const storePendingSIPs = (state: any, action: any) => {
  return {
    ...state,
    pending: action.data,
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.STORE_SIP_DUES:
      return storeSIPDues(state, action);
    case actions.STORE_CANCELLED_SIPS:
      return storeCancelledSIPs(state, action);
      case actions.STORE_PENDING_SIPS:
      return storePendingSIPs(state, action);
    default:
      return state;
  }
};

export default reducer;
