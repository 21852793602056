import { IonItem, IonLabel, IonIcon } from "@ionic/react";
interface WidgetProps {
  title?: string;
  content?: any;
}
const StatusWidget: React.FC<WidgetProps> = ({ title, content}) => {
  return (
    <div className="rounded-container">
      <h1>{content}</h1>
      <p>{title}</p>
    </div>
  );
};
export default StatusWidget;
