import { IonSkeletonText } from "@ionic/react";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonAvatar,
  IonIcon,
  IonItem,
  IonLabel,
  IonToast,
  IonCardContent,
  IonMenuButton,
  IonButton,
  IonPage,
  IonRow,
  IonInput,
  IonChip,
  IonBadge,
  IonCardSubtitle,
  IonCardTitle,
  IonText,
  IonList,
  IonCheckbox,
} from "@ionic/react";
import { swapVerticalOutline } from "ionicons/icons";

interface TableProps {
  header?: any;
  records?: any;
  loading?: boolean;
  isEmpty?: boolean;
  message?: string;
}
const Table: React.FC<TableProps> = ({
  header,
  records,
  loading,
  isEmpty,
  message,
}) => {
  const getHeader = () => {
    let _header: any = [];
    header.map((item: any, index: any) => {
      _header.push(<th>{item}</th>
      );
    });
    return _header;
  };

  const getRecords = () => {
    let _row: any = [];
    records.map((tr: any, idx1: any) => {
      let _col: any = [];
      tr.map((td: any, idx2: any) => {
        _col.push(<td id={idx2}>{td}</td>);
      });
      _row.push(<tr id={idx1}>{_col}</tr>);
    });
    return _row;
  };
  const getSkeletonRecords = () => {
    let _row: any = [];
    for (let i = 0; i < 10; i++) {
      let _col: any = [];
      for (let j = 0; j < header.length; j++) {
        _col.push(
          <td>
            {
              <IonSkeletonText
                animated
                style={{ width: "80%", height: "20px" }}
              />
            }
          </td>
        );
      }
      _row.push(<tr>{_col}</tr>);
    }
    return _row;
  };

  const showRecords = () => {
    if (loading) {
      return <tbody>{getSkeletonRecords()}</tbody>;
    } else {
      if (isEmpty) {
        return (
          <div className="alert alert-warning ion-text-center">{message}</div>
        );
      } else {
        return <tbody>{getRecords()}</tbody>;
      }
    }
  };

  return (
    <table>
      {header != null && !isEmpty ? (
        <thead>
          <tr>{getHeader()}</tr>
        </thead>
      ) : null}
      {showRecords()}
    </table>
  );
};
export default Table;
