import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonList,IonItem,IonLabel,IonBadge } from '@ionic/react';
import { useParams } from 'react-router';
import Header from '../components/Header';

const Aum: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  return (
    <IonPage>
      <Header title="Asset Under Management (AUM)"/>

      <IonContent fullscreen class="ion-padding app-bg">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
        <IonItem>
      <IonLabel>
        <h2>KARPT001</h2>
        <h3>NAV Load from AMFI</h3>
        <p>Last Executed on : 08-04-2021 02:00 AM</p>  
      </IonLabel>
      <IonBadge slot="end">SUCCESS</IonBadge>
    </IonItem>
    <IonItem>
    <IonLabel>
        <h2>KARPT002</h2>
        <h3>Scheme Master Load from BSE</h3>
        <p>Last Executed on : 08-04-2021 02:00 AM</p>  
      </IonLabel>
      <IonBadge color="danger" slot="end">FAILED</IonBadge>
    </IonItem>
     
    </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Aum;
