import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonToast,
  IonText,
  IonLoading,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";

import { UserContext } from "../App";
import API from "../api";
import { userLogin,getUserProfile } from "../store/actions";
import Alert from "../components/Alert";

const Login: React.FC = () => {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [alert, setAlert] = useState<any>({ type: "", message: "" });
  const [showLoading, setShowLoading] = useState(false);
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const authData = {
    username: userName,
    password: password,
    grant_type: "password",
    scopes: "write",
  };

  const loginClick = () => {
    if (userName === "" || password === "") {
      setAlert({
        type: "error",
        message: "Invalid Credentials",
      });
    } else {
      setShowLoading(true);
      API.userLogin(authData)
        .then((result) => {
          setShowLoading(false);
          user.setIsLoggedIn(true);
          localStorage.setItem("token", result.data.access_token);
          dispatch(userLogin(result.data));
          dispatch(getUserProfile(userName));
        })
        .catch((error) => {
          setShowLoading(false);
          if (error.response) {
            setAlert({
              type: "error",
              message: error.response.data.error_description
            });
          } else if (error.request) {
            setAlert({ type: "error", message: "Technical error. Please contact support@kashly.in" });
          } else {
            setAlert({ type: "error", message: "Technical error. Please contact support@kashly.in" });
          }
        });
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen className="login-page">
        <IonGrid fixed={true}>
          <IonRow>
            <IonCol size="12" size-lg="2"></IonCol>
            <IonCol size="12" size-lg="8" className="ion-text-center">
              <br />
              <br />
              <br />
              <h1>Konsole</h1>

              <IonRow>
                <IonCol size="12" size-xl="2"></IonCol>
                <IonCol
                  size="12"
                  size-xl="8"
                  class="ion-padding"
                  className="max-height"
                >
                  <div className="login-wrapper">
                    <IonList>
                      <IonItem>
                        <IonLabel position="floating">Username</IonLabel>

                        <IonInput
                          class="ion-text-uppercase"
                          value={userName}
                          onIonChange={(e: any) => setUserName(e.target.value)}
                          required
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                          value={password}
                          onIonChange={(e: any) => setPassword(e.target.value)}
                          required
                          type="password"
                        />
                      </IonItem>
                      <p />
                      <Alert alert={alert} />
                      <br />
                      <IonButton
                        fill="solid"
                        expand="block"
                        routerLink="/"
                        onClick={loginClick}
                      >
                        <IonText color="light">Login</IonText>
                      </IonButton>
                      <IonText class="ion-text-center" className="text-s">
                        <a href="/reset">
                          <p>Forgot Password?</p>
                        </a>
                      </IonText>
                    </IonList>
                  </div>
                </IonCol>
                <IonCol size="12" size-xl="2"></IonCol>
              </IonRow>
              <div className="web-content-footer">
                <p className="text-xs">
                  You are trying to access the system restricted to authorized
                  users.If you are not the owner of this login or have not been
                  authorized to access this system, please log out immediately.
                  <br />
                </p>
              </div>
            </IonCol>
            <IonCol size="12" size-lg="2"></IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
          duration={1000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
