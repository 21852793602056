import { combineReducers } from "redux";
import authReducer from "./user";
import investorReducer from "./investor";
import transactionReducer from "./transaction";
export const rootReducer = combineReducers({
  user: authReducer,
  investor: investorReducer,
  transaction: transactionReducer
});

export type RootState = ReturnType<typeof rootReducer>;
