import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonButton,
  IonModal,
  IonMenuButton,
  IonBadge,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonText,
} from "@ionic/react";
import moment from "moment";
import { eyeOutline, podiumOutline } from "ionicons/icons";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { RootState } from "../store/reducers";
import { formatScheme, currencyFormat, getAMCIcon } from "../Utilities";

import "../theme/styles.css";
import Table from "../components/Table";
import Widget from "../components/Widget";

import {
  getAllSIPDues,
  getCancelledSIPs,
  getPendingSIPs,
} from "../store/actions";
import {
  dueSipHeader,
  cancelledSIPHeader,
  pendingSIPHeader,
  getCancelledEntry,
} from "../services/SIPServices";

const SIPTracker: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const sipdues: any = useSelector<RootState>(
    (state) => state.transaction.dues
  );
  const cancelled_sips: any = useSelector<RootState>(
    (state) => state.transaction.cancelled
  );

  const pendingSipTransactions: any = useSelector<RootState>(
    (state) => state.transaction.pending
  );

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("0");
  const [duesips, setDuesips] = useState<any>([]);
  const [cancelledSips, setCancelledSips] = useState<any>([]);
  const [pendingSips, setPendingSips] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cancelledEntry, setCancelledEntry] = useState<any>({
    amcCode: "",
    folio_number: "",
    schemeName: "",
    investor: "",
    bank: "",
    amount: "",
    regDate: "",
    cancelDate: "",
    trigger: "",
    rta: "",
    remarks: "",
  });

  const setDueTransactions = (trxns: any) => {
    const _sipdues: any = [];
    trxns.map((trxn: any, index: any) => {
      var _badge = "";
      if (trxn.delay >= 5) {
        _badge = "danger";
      } else if (trxn.delay >= 3) {
        _badge = "warning";
      } else {
        _badge = "light";
      }
      /* show only 3 days delayed transactions */
      if (trxn.delay >= 3) {
        _sipdues.push([
          trxn.folioNumber,
          formatScheme(trxn.schemeName),
          trxn.investorName,
          currencyFormat(trxn.sipAmount),
          trxn.sipDate.substr(0, 10),
          <IonBadge color={_badge}>
            <span className="text-xs">{trxn.status}</span>
          </IonBadge>,
        ]);
      }
    });
    setDuesips(_sipdues);
  };

  const handleViewDetails = (trxn: any) => {
    setShowModal(true);
    setCancelledEntry({
      amcCode: trxn.amcCode,
      folio_number: trxn.folioNumber,
      schemeName: trxn.schemeName,
      investor: trxn.investorName,
      bank: trxn.bankName,
      amount: trxn.sipAmount,
      regDate: trxn.registrationDate,
      cancelDate: trxn.cancelledDate,
      trigger: trxn.triggerDay,
      rta: trxn.rtaCode,
      remarks: trxn.remarks,
    });
  };
  const setCancelledTransactions = (trxns: any) => {
    const _cancelled_sips: any = [];
    trxns.map((trxn: any, index: any) => {
      _cancelled_sips.push([
        <img className="amc-icon" src={getAMCIcon(trxn.amcCode)} />,
        <>
          {formatScheme(trxn.schemeName)}
          <br />
          <span className="text-s">Folio No: {trxn.folioNumber}</span>
        </>,
        trxn.investorName.substr(0, 30),
        currencyFormat(trxn.sipAmount),
        trxn.cancelledDate.substr(0, 10),
        trxn.triggerDay,
        <IonButton
          size="small"
          onClick={(e) => {
            handleViewDetails(trxn);
          }}
        >
          View
        </IonButton>,
      ]);
    });
    setCancelledSips(_cancelled_sips);
  };

  const setPendingTransactions = (trxns: any) => {
    const _pendingSips: any = [];
    trxns.map((trxn: any, index: any) => {
      _pendingSips.push([
        <img className="amc-icon" src={getAMCIcon(trxn.amcCode)} />,
        <>
          {formatScheme(trxn.schemeName)}
          <br />
          <span className="text-s">Folio No: {trxn.folioNumber}</span>
        </>,
        trxn.investorName.substr(0, 30),
        currencyFormat(trxn.sipAmount),
        trxn.registrationDate.substr(0, 10),
        trxn.rtaCode,
      ]);
    });
    setPendingSips(_pendingSips);
  };

  const showSIPDues = (loading: boolean) => {
    return (
      <Widget
        data={
          <>
            <IonSegment
              value={selectedTab}
              onIonChange={(e) => setSelectedTab(e.detail.value!)}
            >
              <IonSegmentButton mode="md" value="0">
                <IonLabel>PROCESSING</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton mode="md" value="1">
                <IonLabel>CANCELLED</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton mode="md" value="2">
                <IonLabel>PENDING</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <br />
            {showTabContent(selectedTab)}
          </>
        }
      />
    );
  };

  const showTabContent = (tabOption: any) => {
    if (tabOption == "0") {
      return (
        <Table header={dueSipHeader} records={duesips} loading={loading} />
      );
    }
    if (tabOption == "1") {
      return (
        <Table
          header={cancelledSIPHeader}
          records={cancelledSips}
          loading={loading}
        />
      );
    }
    if (tabOption == "2") {
      return (
        <Table
          header={pendingSIPHeader}
          records={pendingSips}
          loading={loading}
        />
      );
    }
  };

  useEffect(() => {
    if (pendingSipTransactions != null) {
      setPendingTransactions(pendingSipTransactions);
    }
  }, [pendingSipTransactions]);

  useEffect(() => {
    if (cancelled_sips != null) {
      setCancelledTransactions(cancelled_sips);
    }
  }, [cancelled_sips]);

  useEffect(() => {
    if (sipdues != null) {
      setDueTransactions(sipdues);
      setLoading(false);
    }
  }, [sipdues]);

  useEffect(() => {
    var startDate = moment().subtract(2, "months").format("YYYY-MM-DD");
    var endDate = moment().format("YYYY-MM-DD");
    setLoading(true);
    dispatch(getAllSIPDues());
    dispatch(getPendingSIPs(startDate, "9999-12-31"));
    dispatch(getCancelledSIPs(startDate, endDate));
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>SIP Tracker</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen class="ion-padding app-bg">
        <IonGrid>
          <IonRow>
            <IonCol size="6" size-lg="3">
              <div className="rounded-container">
                <h1>0</h1>
                <p>Total Investors</p>
              </div>
            </IonCol>
            <IonCol size="6" size-lg="3">
              <div className="rounded-container">
                <h1>0</h1>
                <p>Total AUM</p>
              </div>
            </IonCol>
            <IonCol size="6" size-lg="3">
              <div className="rounded-container">
                <h1>0</h1>
                <p>Monthly SIP</p>
              </div>
            </IonCol>
            <IonCol size="6" size-lg="3">
              <div className="rounded-container">
                <h1>0</h1>
                <p>Total Lumpsum</p>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>{showSIPDues(loading)}</IonRow>
        </IonGrid>
        <IonModal
          isOpen={showModal}
          onDidDismiss={() => setShowModal(false)}
          cssClass="cancellation-modal"
        >
          <IonText>{getCancelledEntry(cancelledEntry)}</IonText>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default SIPTracker;
