import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonNote,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect } from "react";
import { podiumOutline } from "ionicons/icons";
import { useParams } from "react-router";
import "./Transact.scss";
import Razorpay from "razorpay";

const Transact: React.FC = () => {
  const { name } = useParams<{ name: string }>();

  const loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  const handlePayment = () => {

    const razorpay = new Razorpay({
      key_id: "rzp_test_IckCSzwy1pN5dL",
      key_secret: "fL9eNioKt6KIL7eSumuknFCB",
    });

    var options = {
      key: "rzp_test_IckCSzwy1pN5dL", // Enter the Key ID generated from the Dashboard
      amount: "1000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Acme Corp",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: "order_J0Cc4dqCJLTots", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response: any) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
      },
      prefill: {
        name: "Gaurav Kumar",
        email: "gaurav.kumar@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
    //paymentObject.open();

    /* instance.orders.create({
      amount: 50000,
      currency: "INR",
      receipt: "receipt#1",
      notes: {
        key1: "value3",
        key2: "value2"
      }
    })
    */
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Transact</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen class="ion-padding app-bg">
        <IonGrid>
          <div className="container">
            <IonLabel>AMC Name</IonLabel>
            <br />
            <select>
              <option>ICICI Prudential Asset Management Company Limited</option>
              <option>HDFC Limited</option>
              <option>SBI Funds</option>
            </select>
            <br />
            <br />

            <IonLabel>Fund Name</IonLabel>
            <br />
            <select>
              <option>ICICI Prudential Sensex ETF</option>
              <option>Axis Long Term Equity Fund - Regular Plan</option>
              <option>SBI Equity Savings Fund - Regular Plan</option>
            </select>
            <br />
            <br />
            <IonLabel>Buy/Sell</IonLabel>
            <br />
            <select>
              <option>Buy</option>
              <option>Sell</option>
            </select>
            <br />
            <br />
            <IonLabel>Lumpsum/SIP</IonLabel>
            <br />
            <select>
              <option>Lumpsum</option>
              <option>SIP</option>
            </select>
            <br />
            <br />
            <IonLabel>SIP Frequency</IonLabel>
            <br />
            <select>
              <option>Weekly</option>
              <option>Monthly</option>
              <option>Quaterly</option>
            </select>
            <br />
            <br />
            <IonLabel>SIP Start Date</IonLabel>
            <br />
            <input type="date" />
            <br />
            <br />
            <IonLabel>Amount</IonLabel>
            <br />
            <input type="number"></input>
            <br />
            <br />
            <IonButton onClick={handlePayment}>Buy</IonButton>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default Transact;
