import { jsPDF } from "jspdf";
import {
  robotoNormalFont,
  robotoBoldFont,
  currencyFormat,
  formatScheme,
} from "../Utilities";

export const holdingsStatementPDF = (
  investor: any,
  holdingSummary: any,
  holdings: any
) => {
  const doc = new jsPDF();

  // document fonts
  doc.addFileToVFS("roboto-normal.ttf", robotoNormalFont);
  doc.addFont("roboto-normal.ttf", "roboto", "normal");
  doc.addFileToVFS("roboto.black-bold.ttf", robotoBoldFont);
  doc.addFont("roboto.black-bold.ttf", "roboto", "bold");

  // document properties
  doc.setProperties({
    title: "Investment Summary",
    subject: "Investment Summary",
    author: "Kashly",
    keywords: "kashly, statement",
    creator: "Konsole by Kashly",
  });

  // document coordinates & parms
  let leftMargin = 10;
  let ypos = 0;

  // report logo
  doc.addImage("/assets/images/report-logo.png", "PNG", 10, 15, 30, 10);

  // company address
  doc.setFont("roboto", "normal");
  doc.setFontSize(10);
  doc.setTextColor(51);
  doc.text("Capsquare Technologies Pvt Ltd", 10, 30);
  doc.text("8/44K, Noah's Ark, First Floor, Suite 144", 10, 35);
  doc.text("Nedumbassery, Ernakulam, Kerala", 10, 40);
  doc.text("CIN - U72900KL2021PTC068649", 10, 45);

  // Investor & distributor table header
  doc.setFont("roboto", "bold");
  doc.setFontSize(9);
  doc.setDrawColor(102, 102, 102);
  doc.setFillColor(243, 243, 243);
  doc.rect(leftMargin, 60, 106, 8, "FD");
  doc.rect(106, 60, 90, 8, "FD");
  doc.text("Investor", 12, 65);
  doc.text("Distributor", 108, 65);
  doc.rect(leftMargin, 68, 96, 26, "D");
  doc.rect(106, 68, 90, 26, "D");

  // Investor & distributor details
  doc.text("Name : ", leftMargin + 2, 75);
  doc.text("PAN : ", leftMargin + 2, 80);
  doc.text("Email : ", leftMargin + 2, 85);
  doc.text("Member since : ", leftMargin + 2, 90);

  doc.setFont("roboto", "normal");

  doc.text(investor.name, leftMargin + 14, 75);
  doc.text(investor.pan, leftMargin + 12, 80);
  doc.text(investor.email, leftMargin + 14, 85);
  doc.text(investor.member_since, leftMargin + 26, 90);

  doc.setFontSize(9);
  doc.text("Capsquare Technologies Pvt Ltd", leftMargin + 98, 75);
  doc.text("AMFI Registered Mutual Fund Distributor", leftMargin + 98, 80);
  doc.text("Corporate ARN - 186781", leftMargin + 98, 85);
  doc.text("CIN - U72900KL2021PTC068649", leftMargin + 98, 90);

  // Notes
  ypos = 92;
  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.setTextColor(150);
  doc.text(
    "It is mandatory for all investors (new and existing) to provide information related to FATCA (Foreign Account Tax Compliance Act). Please contact us",
    leftMargin,
    ypos + 10
  );
  doc.text("for any queries on support@kashly.in", leftMargin, ypos + 14);
  doc.text(
    "In case there is any change in your KYC information please update the same by using the prescribed ‘KYC change request form’ and submit the same",
    leftMargin,
    ypos + 22
  );
  doc.text(
    "the Point of Service of any KYC Registration Agency.",
    leftMargin,
    ypos + 26
  );

  // holding summary
  ypos = 125;
  doc.setDrawColor(102, 102, 102);
  doc.setFillColor(243, 243, 243);
  doc.rect(leftMargin, ypos, 186, 8, "FD");
  doc.setFont("roboto", "bold");
  doc.setFontSize(9);
  doc.setTextColor(51);
  doc.text(
    "Holdings summary as on " + new Date().toLocaleString().substring(0, 10),
    12,
    ypos + 5
  );

  doc.rect(leftMargin, ypos + 8, 50, 8, "D");
  doc.rect(leftMargin + 50, ypos + 8, 50, 8, "D");
  doc.rect(leftMargin + 100, ypos + 8, 50, 8, "D");
  doc.rect(leftMargin + 150, ypos + 8, 36, 8, "D");
  doc.text("Total Investment", leftMargin + 2, ypos + 13);
  doc.text("Current Value", leftMargin + 52, ypos + 13);
  doc.text("P&L Amount", leftMargin + 102, ypos + 13);
  doc.text("P&L %", leftMargin + 152, ypos + 13);

  doc.rect(leftMargin, ypos + 16, 50, 8, "D");
  doc.rect(leftMargin + 50, ypos + 16, 50, 8, "D");
  doc.rect(leftMargin + 100, ypos + 16, 50, 8, "D");
  doc.rect(leftMargin + 150, ypos + 16, 36, 8, "D");

  doc.setFont("roboto", "normal");
  doc.text(
    currencyFormat(holdingSummary.invested_amount).toString() + " INR",
    leftMargin + 2,
    ypos + 21
  );
  doc.text(
    currencyFormat(holdingSummary.current_value).toString() + " INR",
    leftMargin + 52,
    ypos + 21
  );
  doc.text(
    currencyFormat(holdingSummary.abs_pnl).toString() + " INR",
    leftMargin + 102,
    ypos + 21
  );
  doc.text(
    holdingSummary.abs_pnl_percent.toString(),
    leftMargin + 152,
    ypos + 21
  );

  // portfolio summary
  ypos = 160;
  doc.setDrawColor(102, 102, 102);
  doc.setFillColor(243, 243, 243);
  doc.rect(leftMargin, ypos, 186, 8, "FD");
  doc.setFont("roboto", "bold");
  doc.setFontSize(9);
  doc.text(
    "Portfolio summary as on " + new Date().toLocaleString().substring(0, 10),
    12,
    ypos + 5
  );

  // portfolio header
  ypos = ypos + 8;
  doc.rect(leftMargin, ypos, 30, 8, "D");
  doc.rect(leftMargin + 30, ypos, 30, 8, "D");
  doc.rect(leftMargin + 60, ypos, 25, 8, "D");
  doc.rect(leftMargin + 85, ypos, 25, 8, "D");
  doc.rect(leftMargin + 110, ypos, 30, 8, "D");
  doc.rect(leftMargin + 140, ypos, 30, 8, "D");
  doc.rect(leftMargin + 170, ypos, 16, 8, "D");

  doc.text("Folio Number ", leftMargin + 2, ypos + 5);
  doc.text("Investment", leftMargin + 32, ypos + 5);
  doc.text("Units", leftMargin + 62, ypos + 5);
  doc.text("NAV", leftMargin + 87, ypos + 5);
  doc.text("Current Value", leftMargin + 112, ypos + 5);
  doc.text("P&L Amount", leftMargin + 142, ypos + 5);
  doc.text("P&L %", leftMargin + 172, ypos + 5);

  // portfolio records
  ypos = ypos + 8;
  holdings.map((holding: any, key: any) => {
    //scheme name
    doc.setDrawColor(102, 102, 102);
    doc.setFillColor(243, 243, 243);
    doc.rect(leftMargin, ypos, 186, 8, "FD");
    doc.setFont("roboto", "bold");
    doc.setFontSize(9);
    doc.text(formatScheme(holding.scheme_name).toString(), 12, ypos + 5);

    doc.rect(leftMargin, ypos + 8, 30, 8, "D");
    doc.rect(leftMargin + 30, ypos + 8, 30, 8, "D");
    doc.rect(leftMargin + 60, ypos + 8, 25, 8, "D");
    doc.rect(leftMargin + 85, ypos + 8, 25, 8, "D");
    doc.rect(leftMargin + 110, ypos + 8, 30, 8, "D");
    doc.rect(leftMargin + 140, ypos + 8, 30, 8, "D");
    doc.rect(leftMargin + 170, ypos + 8, 16, 8, "D");

    doc.setFont("roboto", "normal");
    doc.text(holding.folio_number, leftMargin + 2, ypos + 13);
    doc.text(
      currencyFormat(holding.invested_amount).toString() + " INR",
      leftMargin + 32,
      ypos + 13
    );
    doc.text(holding.units.toString(), leftMargin + 62, ypos + 13);
    doc.text(holding.nav.toString(), leftMargin + 87, ypos + 13);
    doc.text(
      currencyFormat(holding.current_value).toString() + " INR",
      leftMargin + 112,
      ypos + 13
    );
    doc.text(
      currencyFormat(holding.abs_pnl).toString() + " INR",
      leftMargin + 142,
      ypos + 13
    );
    doc.text(holding.abs_pnl_percent.toString(), leftMargin + 172, ypos + 13);

    ypos = ypos + 16;

    if (ypos >= 270) {
      doc.addPage();
      ypos = 20;
    }
  });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.setTextColor(150);
  doc.text(
    "This is a computer generated statement. Please contact us through support@kashly.in for any queries or discrepancy about your statement.",
    leftMargin,
    ypos + 10
  );
  doc.text(
    "Mutual Funds investments are subject to market risks, read all scheme related documents carefully. Past performance is not an indicator for future ",
    leftMargin,
    ypos + 18
  );
  doc.text(
    "returns. Kashly is only an aggregation platform that collects investment details of investors from the mutual fund registrars. The application is not ",
    leftMargin,
    ypos + 22
  );
  doc.text(
    "an order placement platform hence Kashly is not liable or responsible and does not represent or warrant for any damages regarding non-execution of ",
    leftMargin,
    ypos + 26
  );
  doc.text(
    "orders or any incorrect execution of orders with regard to the funds that happen outside of our platform.",
    leftMargin,
    ypos + 30
  );

  doc.save(
    investor.name +
      "_Kashly Holdings Statement_" +
      new Date().toLocaleString().substring(0, 10) +
      ".pdf"
  );
  //doc.output("dataurlnewwindow");
};

export const applicationPDF = (application: any) => {
  const doc = new jsPDF();

  // document fonts
  doc.addFileToVFS("roboto-normal.ttf", robotoNormalFont);
  doc.addFont("roboto-normal.ttf", "roboto", "normal");
  doc.addFileToVFS("roboto.black-bold.ttf", robotoBoldFont);
  doc.addFont("roboto.black-bold.ttf", "roboto", "bold");

  // document properties
  doc.setProperties({
    title: "Kashly Application",
    subject: "Kashly Account Opening Form",
    author: "Kashly",
    keywords: "kashly, application",
    creator: "Konsole by Kashly",
  });

  // report logo
  doc.addImage("/assets/images/report-logo.png", "PNG", 15, 15, 30, 10);

  // company address
  doc.setFont("roboto", "normal");
  doc.setFontSize(10);
  doc.setTextColor(51);
  doc.text("Capsquare Technologies Pvt Ltd", 15, 30);
  doc.text("8/44K, Noah's Ark, First Floor, Suite 144", 15, 35);
  doc.text("Nedumbassery, Ernakulam, Kerala", 15, 40);
  doc.text("CIN - U72900KL2021PTC068649", 15, 45);

  doc.setFont("roboto", "bold");
  doc.setFontSize(9);

  // document coordinates & parms
  let leftMargin = 15;
  let xpos = leftMargin;
  let ypos = 60;

  doc.setFontSize(14);
  doc.text("Kashly Online Application", xpos, ypos + 5);

  ypos+=15

  doc.setFontSize(10);
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("First Name", xpos + 2, ypos + 5);
  doc.text("Last Name", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.firstName ? application.firstName: "", xpos + 32, ypos + 5);
  doc.text(application.lastName ? application.lastName: "", xpos + 122, ypos + 5);

  ypos+=8
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Email", xpos + 2, ypos + 5);
  doc.text("Contact", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.email ? application.email: "", xpos + 32, ypos + 5);
  doc.text(application.contact ? application.contact: "", xpos + 122, ypos + 5);

  ypos+=20

  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 150, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Address Line 1", xpos + 2, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.address1 ?application.address1 : "", xpos + 32, ypos + 5);

  ypos+=8

  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 150, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Address Line 2", xpos + 2, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.address2 ? application.address2 : "", xpos + 32, ypos + 5);

  ypos+=8
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("City", xpos + 2, ypos + 5);
  doc.text("State", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.city ?  application.city: "", xpos + 32, ypos + 5);
  doc.text(application.state? application.state: "", xpos + 122, ypos + 5);

  ypos+=8
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Country", xpos + 2, ypos + 5);
  doc.text("Zip", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.country? application.country:"", xpos + 32, ypos + 5);
  doc.text(application.zip? application.zip: "", xpos + 122, ypos + 5);

  ypos+=20
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Gender", xpos + 2, ypos + 5);
  doc.text("DOB", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.gender ? application.gender : "", xpos + 32, ypos + 5);
  doc.text(application.dob ? application.dob: "", xpos + 122, ypos + 5);

  ypos+=8
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Nationality", xpos + 2, ypos + 5);
  doc.text("Country Of Birth", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.nationality ? application.nationality : "", xpos + 32, ypos + 5);
  doc.text(application.birthCountry ? application.birthCountry: "", xpos + 122, ypos + 5);

  ypos+=8
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Place Of Birth", xpos + 2, ypos + 5);
  doc.text("Father's Name", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.birthCity ? application.birthCity : "", xpos + 32, ypos + 5);
  doc.text(application.fatherName ? application.fatherName : "", xpos + 122, ypos + 5);

  ypos+=8
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Spouse's Name", xpos + 2, ypos + 5);
  doc.text("Occupation", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.spouseName ? application.spouseName : "", xpos + 32, ypos + 5);
  doc.text(application.occupation ? application.occupation: "", xpos + 122, ypos + 5);

  ypos+=8
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 150, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Annual Income", xpos + 2, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.annualIncome ? application.annualIncome : "" + " INR (Gross)", xpos + 32, ypos + 5);

  ypos+=20
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Nominee Name", xpos + 2, ypos + 5);
  doc.text("Nominee DOB", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.nomineeName ? application.nomineeName : "", xpos + 32, ypos + 5);
  doc.text(application.nomineeDob ? application.nomineeDob : "", xpos + 122, ypos + 5);

  ypos+=8

  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 150, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Relation", xpos + 2, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.nomineeRelation ? application.nomineeRelation : "", xpos + 32, ypos + 5);

  ypos+=20
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Account No", xpos + 2, ypos + 5);
  doc.text("Account Type", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.accountNumber ? application.accountNumber : "", xpos + 32, ypos + 5);
  doc.text(application.accountType ? application.accountType : "", xpos + 122, ypos + 5);

  ypos+=8
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("IFSC Code", xpos + 2, ypos + 5);
  doc.text("MICR Code", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.ifsc ? application.ifsc: "", xpos + 32, ypos + 5);
  doc.text(application.micr ? application.micr: "", xpos + 122, ypos + 5);

  ypos+=8
  
  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 60, 8, "D");
  doc.rect(xpos+90, ypos, 30, 8, "FD");
  doc.rect(xpos+120, ypos, 60, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("PAN", xpos + 2, ypos + 5);
  doc.text("Tax Status", xpos + 92, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.pan ? application.pan: "", xpos + 32, ypos + 5);
  doc.text(application.taxStatus ? application.taxStatus: "", xpos + 122, ypos + 5);

  ypos+=8

  doc.setFillColor(243, 243, 243);
  doc.rect(xpos, ypos, 30, 8, "FD");
  doc.rect(xpos+30, ypos, 150, 8, "D");
  doc.setTextColor(120);
  doc.setFont("roboto","bold");
  doc.text("Tax Country", xpos + 2, ypos + 5);
  doc.setTextColor(0);
  doc.setFont("roboto","normal");
  doc.text(application.taxCountry? application.taxCountry: "", xpos + 32, ypos + 5);


  ypos+=20

  doc.setFont("roboto","normal");
  doc.setFontSize(9);
  doc.setTextColor(120);
  doc.text(
    "This is a computer generated account opening form. Kindly review the document before processing.",
    xpos,
    ypos
  );

  ypos+=4

  doc.setFont("roboto","normal");
  doc.setFontSize(9);
  doc.setTextColor(120);
  doc.text(
    "Please contact us support@kashly.in for any queries or discrepancy about the application. ",
    xpos,
    ypos
  );

  doc.save("Kashly_AOF_"+application.firstName+".pdf");
};
