import { IonText, IonBadge, IonSelectOption } from "@ionic/react";
import { getAMCIcon, formatScheme, currencyFormat } from "../Utilities";

export const HoldingsHeader = [
  "AMC",
  "Scheme",
  "Invested Amount",
  "Latest Amount",
  "Total Units",
  "P&L Amount",
  "P&L %",
];

export const TransactionsHeader = [
  "AMC",
  "Scheme",
  "Transaction Date",
  "Purchased Date",
  "Amount",
  "Units",
  "Price",
  "Type",
];

export const activeSIPHeader = [
  "AMC",
  "Scheme",
  "Registered",
  "Amount",
  "RTA",
  "Trigger",
  "Status",
];

export const getHoldingsRecords = (holdingsArray: any) => {
  var _records: any = [];
  holdingsArray.map((fund: any, key: number) => {
    _records.push([
      <img className="amc-icon" src={getAMCIcon(fund.amc_code)} />,
      <>
        {formatScheme(fund.scheme_name)}
        <br />
        <span className="text-s">Folio No: {fund.folio_number}</span>
      </>,
      currencyFormat(fund.invested_amount),
      currencyFormat(fund.current_value),
      fund.units,
      fund.abs_pnl >= 0 ? (
        <IonText color="success">{currencyFormat(fund.abs_pnl)}</IonText>
      ) : (
        <IonText color="danger">{currencyFormat(fund.abs_pnl)}</IonText>
      ),
      fund.abs_pnl_percent >= 0 ? (
        <IonText color="success">+{fund.abs_pnl_percent}%</IonText>
      ) : (
        <IonText color="danger">{fund.abs_pnl_percent}%</IonText>
      ),
    ]);
  });
  return _records;
};

export const getTransactionRecords = (transactionArray: any, product?: any) => {
  var _records: any = [];
  if (product != null && product != "") {
    transactionArray.map((trxn: any, key: number) => {
      if (product === trxn.product_code) {
        _records.push([
          <img className="amc-icon" src={getAMCIcon(trxn.amc_code)} />,
          <>
            {formatScheme(trxn.scheme)}
            <br />
            <span className="text-s">Folio No: {trxn.folio}</span>
          </>,
          trxn.date.substr(0, 10),
          trxn.date.substr(0, 10),
          currencyFormat(trxn.amount),
          trxn.unit,
          trxn.price,
          <IonBadge color="light">
            <span className="text-xs">{trxn.type.replace("_", " ")}</span>
          </IonBadge>,
        ]);
      }
    });
  } else {
    transactionArray.map((trxn: any, key: number) => {
      _records.push([
        <img className="amc-icon" src={getAMCIcon(trxn.amc_code)} />,
        <>
          {formatScheme(trxn.scheme)}
          <br />
          <span className="text-s">Folio No: {trxn.folio}</span>
        </>,
        trxn.date.substr(0, 10),
        trxn.date.substr(0, 10),
        currencyFormat(trxn.amount),
        trxn.unit,
        trxn.price,
        <IonBadge color="light">
          <span className="text-xs">{trxn.type.replace("_", " ")}</span>
        </IonBadge>,
      ]);
    });
  }

  return _records;
};

export const getActiveSIPRecords = (sips: any) => {
  var _records: any = [];
  sips.map((sip: any, key: number) => {
    _records.push([
      <img className="amc-icon" src={getAMCIcon(sip.amcCode)} />,
      <>
        {formatScheme(sip.scheme)}
        <br />
        <span className="text-s">Folio No: {sip.folio}</span>
      </>,
      sip.regDate.substr(0, 10),
      currencyFormat(sip.amount),
      sip.rta,
      sip.trigger,
      <IonBadge color="light">
        <span className="text-xs">{sip.status}</span>
      </IonBadge>,
    ]);
  });
  return _records;
};

export const getFilterOptions = (funds: any) => {
  var _options: any = [];
  funds.map((fund: any, key: number) => {
    _options.push(
      <option key={key} value={fund.product_code}>
        {formatScheme(fund.scheme_name)}
      </option>
    );
  });
  return _options;
};

export const getTotalSIPAmount = (sips: any) => {
  let totalSIPAmount = 0;
  if (sips) {
    sips.map((sip: any, key: any) => {
      totalSIPAmount = totalSIPAmount + sip.amount;
    });
  }
  return totalSIPAmount
};
