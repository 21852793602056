import { useEffect, useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonButton,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  IonAvatar,
  IonText,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";

import API from "../api";
import {
  HoldingsHeader,
  TransactionsHeader,
  activeSIPHeader,
  getHoldingsRecords,
  getTransactionRecords,
  getActiveSIPRecords,
  getFilterOptions,
  getTotalSIPAmount,
} from "../services/InvestorServices";

import { cloudDownloadOutline, readerOutline } from "ionicons/icons";

import moment from "moment";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { currencyFormat, onlyUnique } from "../Utilities";
import StatusWidget from "../components/StatusWidget";
import HoldingSummary from "../components/HoldingSummary";
import Table from "../components/Table";
import Widget from "../components/Widget";
import { holdingsStatementPDF } from "../services/ReportServices";

const Investor: React.FC = () => {
  const investors: any = useSelector<RootState>((state) => state.investor.list);
  const { id } = useParams<{ id: string }>();
  const [trxnOption, setTrxnOption] = useState(1);
  const [selectedTab, setSelectedTab] = useState("0");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [holdingLoading, setHoldingLoading] = useState(false);
  const [trxnLoading, setTrxnLoading] = useState(false);
  const [activeSIPLoading, setActiveSIPLoading] = useState(false);
  const [filterProduct, setFilterProduct] = useState("");
  const [transactions, setTransactions] = useState<any>([
    {
      id: "",
      scheme: "",
      folio: "",
      date: "",
      amount: "",
      unit: "",
      price: "",
      amc_code: "",
      type: "",
      product_code: "",
    },
  ]);

  const [activeSips, setActiveSIPs] = useState<any>([
    {
      amcCode: "",
      folio: "",
      scheme: "",
      regDate: "",
      amount: "",
      status: "",
      rta: "",
      trigger: "",
      productCode: "",
    },
  ]);

  const [holdings, setHoldings] = useState<any>([
    {
      folio_number: "",
      amc_code: "",
      scheme_name: "",
      abs_pnl: "",
      abs_pnl_percent: "",
      units: "",
      nav: "",
      invested_amount: "",
      current_value: "",
      product_code: "",
    },
  ]);
  const [holdingSummary, setHoldingSummary] = useState<any>({
    abs_pnl: "0",
    abs_pnl_percent: "0",
    invested_amount: "0",
    current_value: "0",
  });
  const [investor, setInvestor] = useState<any>({
    id: id,
    name: "",
    pan: "",
    email: "",
    member_since: "",
  });

  const [investorNote, setInvestorNote] = useState({
    type: "INVESTOR",
    typeId: "",
    note: "",
  });

  const [notes, setNotes] = useState([
    {
      id: 0,
      type: "INVESTOR",
      typeId: "",
      note: "",
      createdBy: "",
      modifiedBy: "",
      createdAt: "",
      modifiedAt: "",
    },
  ]);

  const addNote = () => {
    if (investorNote.note != "") {
      API.createNote(investorNote)
        .then((result) => {
          if (result.data.status === "success") {
            getInvestorNotes(id);
            setInvestorNote({ type: "INVESTOR", typeId: "", note: "" });
          }
        })
        .catch((error) => {
          setInvestorNote({ type: "INVESTOR", typeId: "", note: "" });
        });
    }
  };

  const getInvestorNotes = (id: any) => {
    API.getNotes(id, "INVESTOR")
      .then((result) => {
        if (result.data.status === "success" && result.data.data.length > 0) {
          setNotes(result.data.data);
        }
      })
      .catch((error) => {});
  };

  const getInvestorProfile = () => {
    var _investor: any = {};
    investors.map((investor: any, key: any) => {
      if (investor.profile.uccId == id) {
        _investor = {
          id: investor.profile.uccId,
          name: investor.firstName + " " + investor.lastName,
          pan: investor.profile.pan,
          email: investor.email,
          member_since: investor.createdAt.substring(0, 10),
        };
      }
    });
    return _investor;
  };

  const showInvestorProfile = (investor: any) => {
    return (
      <IonCol>
        <div className="investor-profile-card">
          <IonItem className="investor-card" lines="none">
            <IonAvatar slot="start">
              <img src="assets/images/user.png" />
            </IonAvatar>
            <IonLabel>
              <IonText color="primary">
                {investor.name + " - " + investor.id}
              </IonText>
              <br />
              <p>
                <br />
                PAN: {investor.pan}
                <br />
                {investor.email}
                <br />
                Member Since: {investor.member_since}
              </p>
            </IonLabel>
          </IonItem>
        </div>
      </IonCol>
    );
  };

  const getActiveSIPList = (data: any) => {
    var _sips: any = [];
    data.map((sip: any, key: any) => {
      _sips.push({
        amcCode: sip.amcCode,
        folio: sip.folioNumber,
        scheme: sip.schemeName,
        regDate: sip.registrationDate,
        amount: sip.sipAmount,
        status: sip.status,
        rta: sip.rtaCode,
        trigger: sip.triggerDay,
        productCode: sip.productCode,
      });
    });
    setActiveSIPs(_sips);
  };

  const getTrxnList = (data: any) => {
    var _transactions: any = [];
    data.map((transaction: any, key: any) => {
      _transactions.push({
        id: transaction.transactionId,
        scheme: transaction.rtaSchemeName,
        folio: transaction.folioNumber,
        date: transaction.transactionDate,
        amount: transaction.amount,
        unit: transaction.units,
        price: transaction.purchasePrice,
        amc_code: transaction.rtaAmcCode,
        type: transaction.transactionType,
        product_code: transaction.rtaProductCode,
      });
    });
    setTransactions(_transactions);
  };

  const getHoldingsList = (data: any) => {
    var _holdings: any = [];
    data.map((holding: any, key: any) => {
      _holdings.push({
        folio_number: holding.folioNumber,
        amc_code: holding.rtaAmcCode,
        scheme_name: holding.rtaSchemeName,
        abs_pnl: holding.absPnl,
        abs_pnl_percent: holding.absPnlPercent,
        units: holding.units,
        nav: holding.nav,
        invested_amount: holding.actualAmount,
        current_value: holding.currentValue,
        product_code: holding.rtaProductCode,
      });
    });
    setHoldings(_holdings);
  };

  const clearHoldings = () => {
    setHoldings([
      {
        folio_number: "",
        amc_code: "",
        scheme_name: "",
        abs_pnl: "",
        abs_pnl_percent: "",
        units: "",
        nav: "",
        invested_amount: "",
        current_value: "",
        product_code: "",
      },
    ]);
  };

  const getHoldingSummary = (data: any) => {
    setHoldingSummary({
      abs_pnl: data.pnlAmount,
      abs_pnl_percent: data.pnlPercent,
      invested_amount: data.totalInvested,
      current_value: data.currentValue,
    });
  };

  const clearHoldingSummary = () => {
    setHoldingSummary({
      abs_pnl: "0",
      abs_pnl_percent: "0",
      invested_amount: "0",
      current_value: "0",
    });
  };

  const handleSearch = () => {
    fetchTransactions(startDate, endDate);
  };

  const fetchTransactions = (start?: string, end?: string) => {
    var start_date = "";
    var end_date = "";
    if (start != null && end != null) {
      start_date = start;
      end_date = end;
    } else {
      var start_date = moment().subtract(3, "months").format("YYYY-MM-DD");
      var end_date = moment().format("YYYY-MM-DD");
      setStartDate(start_date);
      setEndDate(end_date);
    }
    setTrxnLoading(true);

    API.getTransactions(id, start_date, end_date)
      .then((result) => {
        if (result.data.status == "success") {
          getTrxnList(result.data.data);
          setTrxnLoading(false);
        } else {
          console.log(result.data.message);
        }
      })
      .catch((error) => {
        console.log("Failed to retrieve transactions");
      });
  };

  const fetchActiveSIPs = (id: string) => {
    setActiveSIPLoading(true);
    API.getActiveSIPs(id)
      .then((result) => {
        setActiveSIPLoading(false);
        if (result.data.status == "success") {
          getActiveSIPList(result.data.data);
        }
      })
      .catch((error) => {
        setActiveSIPLoading(false);
        console.log("Failed to retrieve Active SIPs");
      });
  };

  useEffect(() => {
    fetchTransactions();
  }, [trxnOption]);

  useEffect(() => {
    setHoldingLoading(true);
    setInvestor(getInvestorProfile());
    setTransactions([]);
    setNotes([]);
    setFilterProduct("");
    clearHoldings();
    clearHoldingSummary();
    fetchTransactions();
    fetchActiveSIPs(id);
    getInvestorNotes(id);
    API.getHoldings(id)
      .then((result) => {
        if (result.data.status == "success") {
          getHoldingsList(result.data.data.holdings);
          getHoldingSummary(result.data.data);
        } else {
          console.log(result.data.message);
        }
        setHoldingLoading(false);
      })
      .catch((error) => {
        console.log("Failed to retrieve holdings");
        setHoldingLoading(false);
      });
  }, [id]);

  const showTabContent = (tabOption: any) => {
    if (tabOption == "0") {
      return (
        <Table
          header={HoldingsHeader}
          records={getHoldingsRecords(holdings)}
          loading={holdingLoading}
          isEmpty={isHoldingsEmpty(holdings)}
          message={"No Holdings to Show"}
        />
      );
    }
    if (tabOption == "1") {
      return (
        <>
          <div className="transaction-search-wrapper">
            <IonRow>
              <IonCol size="7">
                <IonItem className="transaction-search-item" lines="none">
                  <span className="text-s">Start Date</span>&nbsp;&nbsp;
                  <input
                    className="transaction-search-input"
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                    min="2018-01-01"
                    max={endDate}
                  />
                  &nbsp;&nbsp;
                  <span className="text-s">End Date</span>&nbsp;&nbsp;
                  <input
                    className="transaction-search-input"
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    value={endDate}
                    min="2018-01-01"
                    max={moment().format("YYYY-MM-DD")}
                  />
                  &nbsp;&nbsp;
                  <IonButton onClick={handleSearch}>Search</IonButton>
                </IonItem>
              </IonCol>
              <IonItem className="transaction-search-item" lines="none">
                <select
                  className="transaction-select-input"
                  value={filterProduct}
                  onChange={(e) => {
                    setFilterProduct(e.target.value);
                  }}
                >
                  <option value="">All Funds</option>
                  {getFilterOptions(holdings)}
                </select>
              </IonItem>
              <IonCol></IonCol>
            </IonRow>
          </div>
          <Table
            header={TransactionsHeader}
            records={getTransactionRecords(transactions, filterProduct)}
            loading={trxnLoading}
          />
        </>
      );
    }
    if (tabOption == "2") {
      return (
        <Table
          header={activeSIPHeader}
          records={getActiveSIPRecords(activeSips)}
          loading={activeSIPLoading}
        />
      );
    }
   
  };

  const downloadHoldingStatement = () => {
    holdingsStatementPDF(investor, holdingSummary, holdings);
  };

  const isHoldingsEmpty = (holdings: any) => {
    if (holdings) {
      if (holdings.length > 0) {
        if (holdings[0].folio_number != "") {
          return false;
        }
      }
    }
    return true;
  };

  const getTotalSIPs = (activeSIPs: any) => {
    var totalSIPs = 0;
    var products: any = [];
    if (activeSIPs) {
      activeSIPs.map((sip: any, key: any) => {
        products.push(sip.productCode);
      });
      totalSIPs = products.filter(onlyUnique).length;
    }
    return totalSIPs;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Investor Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen class="ion-padding app-bg">
        <IonGrid>
          <IonRow>
            {showInvestorProfile(investor)}
            <IonCol>
              {holdingSummary ? (
                <HoldingSummary
                  summary={holdingSummary}
                  loading={holdingLoading}
                />
              ) : null}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6" size-lg="3">
              <StatusWidget
                title="Total Funds"
                content={holdings ? holdings.length : 0}
              />
            </IonCol>
            <IonCol size="6" size-lg="3">
              <StatusWidget
                title="Total SIPs"
                content={getTotalSIPs(activeSips)}
              />
            </IonCol>
            <IonCol size="6" size-lg="3">
              <StatusWidget
                title="SIP Amount"
                content={currencyFormat(getTotalSIPAmount(activeSips))}
              />
            </IonCol>
            <IonCol size="6" size-lg="3">
              <StatusWidget title="Total Goals" content={"0"} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton onClick={downloadHoldingStatement}>
                Statement
                <IonIcon slot="start" icon={readerOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <Widget
              data={
                <>
                  <IonRow>
                    <IonSegment
                      className="investment-tabs"
                      value={selectedTab}
                      onIonChange={(e) => setSelectedTab(e.detail.value!)}
                    >
                      <IonSegmentButton mode="md" value="0">
                        <IonLabel>HOLDINGS</IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton mode="md" value="1">
                        <IonLabel>TRANSACTIONS</IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton mode="md" value="2">
                        <IonLabel>SIPS</IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton mode="md" value="3">
                        <IonLabel>GOALS</IonLabel>
                      </IonSegmentButton>
                    </IonSegment>
                  </IonRow>
                  <br />
                  <IonRow>{showTabContent(selectedTab)}</IonRow>
                </>
              }
            />
          </IonRow>
          <IonRow>
            <IonCol>
            <div className="contentWrapper">
              <br/>
              <IonItem lines="none">
                <textarea
                  value={investorNote.note}
                  className="text-area"
                  onChange={(e) =>
                    setInvestorNote({
                      ...investorNote,
                      typeId: id,
                      note: e.target.value,
                    })
                  }
                ></textarea>
              </IonItem>
              <IonItem lines="none">
                <IonButton size="default" onClick={addNote}>
                  Add Note
                </IonButton>
              </IonItem>
              {notes != null
                ? notes.map((note: any, key: any) => {
                    return (
                      <div id={key} className="application-note-entry">
                        <p className="application-note-text">{note.note}</p>
                        <p className="application-note-audit">
                          {note.createdBy} on {note.createdAt}
                        </p>
                      </div>
                    );
                  })
                : null}
            </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Investor;
