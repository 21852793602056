import { useEffect, useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonAvatar,
  IonText,
  IonList,
  IonInput,
  IonModal,
} from "@ionic/react";

import {
  searchOutline,
  addCircleOutline,
  cloudDownloadOutline,
} from "ionicons/icons";

/* Redux store actions */
import { RootState } from "../store/reducers";
import { getAllInvestors } from "../store/actions";

import API from "../api";
import { validateCreateInvestor } from "../Utilities";
import Alert from "../components/Alert";
import StatusWidget from "../components/StatusWidget";
import { useDispatch, useSelector } from "react-redux";
import InvestorCard from "../components/InvestorCard";

const Investors: React.FC = () => {
  const dispatch = useDispatch();

  const investors: any = useSelector<RootState>((state) => state.investor.list);

  const [searchText, setSearchText] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pan, setPAN] = useState("");
  const [alert, setAlert] = useState<any>({ type: "", message: "" });

  const [newInvestors, setNewInvestors] = useState([]);
  const [items, setItems] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [summary, setSummary] = useState({
    total_investors: 0,
    total_aum: 0,
    monthly_sip: 0,
    total_lumpsum: 0,
  });

  const getSearchList = () => {
    var _investors: any = [];
    if (investors) {
      investors.reverse().map((investor: any, index: any) => {
        _investors.push([
          investor.profile.uccId,
          investor.firstName +
            " " +
            investor.lastName +
            " - " +
            investor.profile.uccId,
        ]);
      });
    }
    return _investors;
  };

  const getNewInvestors = () => {
    var _investors: any = [];
    var _count = 0;
    if (investors) {
      investors.reverse().map((investor: any, index: any) => {
        if (_count < 6) {
          _investors.push([
            investor.profile.uccId,
            investor.firstName + " " + investor.lastName,
            investor.profile.pan,
            investor.email,
          ]);
        }
        _count++;
      });
    }
    return _investors;
  };

  const showNewInvestors = (investorList: any) => {
    var _investors: any = [];
    if (loading) {
      for (let i = 0; i <= 6; i++) {
        _investors.push(<InvestorCard loading={loading} />);
      }
    } else {
      investorList.map((investor: any, index: any) => {
        _investors.push(
          <InvestorCard
            id={investor[0]}
            name={investor[1]}
            pan={investor[2]}
            email={investor[3]}
            loading={loading}
          />
        );
      });
    }

    return _investors;
  };

  const handleCreateInvestor = () => {
    var createForm = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      pan: pan,
    };
    var _valid = validateCreateInvestor(createForm);
    if (_valid != "") {
      setAlert({ type: "error", message: _valid });
    } else {
      var user = {
        firstName: firstName,
        lastName: lastName,
        email: email.toLocaleLowerCase(),
        roles: [
          {
            roleName: "ROLE_INVESTOR",
          },
        ],
        profile: {
          pan: pan.toUpperCase(),
        },
      };

      API.createUser(user)
        .then((result) => {
          setAlert({
            type: "success",
            message: result.data.message + " " + result.data.data.userName,
          });
          dispatch(getAllInvestors());
        })
        .catch((error) => {
          if (error.response) {
            setAlert({
              type: "error",
              message: error.response.data.data.message,
            });
          } else if (error.request) {
            console.log("failed request");
            setAlert({ type: "error", message: error.request });
          } else {
            setAlert({ type: "error", message: error.message });
          }
        });

      setAlert({ type: "", message: "" });
    }
  };

  const handleSearch = (text: any) => {
    setSearchText(text);
    var found = false;
    var _items: any = [];

    if (text.length > 1) {
      searchList.forEach((investor: any, key: any) => {
        if (investor[1].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          found = true;
          _items.push(investor);
        }
      });
      if (found) {
        setItems(_items);
      } else {
        found = false;
        setItems(_items);
      }
    } else {
      found = false;
      searchList.map((investor, key) => {
        _items.push(investor);
      });
      setItems(_items);
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getAllInvestors());
  }, []);

  useEffect(() => {
    setNewInvestors(getNewInvestors());
    setSearchList(getSearchList());
    if (investors) {
      setSummary({
        ...summary,
        total_investors: investors.length,
      });
      setLoading(false)
    }
    
  }, [investors]);

  useEffect(() => {
    var _items: any = [];
    searchList.map((investor, key) => {
      _items.push(investor);
    });
    setItems(_items);
  }, [searchList]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Investors</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen class="ion-padding app-bg">
        <IonGrid>
          <IonRow>
            <IonCol size="6" size-lg="3">
              <StatusWidget
                title="Total Investors"
                content={summary.total_investors}
              />
            </IonCol>
            <IonCol size="6" size-lg="3">
              <StatusWidget title="Total AUM" content="0" />
            </IonCol>
            <IonCol size="6" size-lg="3">
              <StatusWidget title="Monthly SIP" content="0" />
            </IonCol>
            <IonCol size="6" size-lg="3">
              <StatusWidget title="Total Lumpsum" content="0" />
            </IonCol>
          </IonRow>
          <br />
          <IonRow>
            <IonCol size="8">
              <IonRow>
                <IonCol>
                  <IonItem
                    className="ion-no-padding section-header"
                    lines="none"
                  >
                    <IonLabel>
                      <h3>New Investors</h3>
                    </IonLabel>
                    <IonButton onClick={() => setCreateUserModal(true)}>
                      New Investor
                      <IonIcon slot="start" icon={addCircleOutline} />
                    </IonButton>
                    <IonButton>
                      Download
                      <IonIcon slot="start" icon={cloudDownloadOutline} />
                    </IonButton>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>{showNewInvestors(newInvestors)}</IonRow>
            </IonCol>
            <IonCol size="4">
              <IonRow>
                <IonCol>
                  <IonItem
                    className="ion-no-padding section-header"
                    lines="none"
                  >
                    <IonLabel>
                      <h3>Search Investors</h3>
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <div className="rounded-container">
                    <IonSearchbar
                      className="search-bar"
                      mode="md"
                      search-icon={searchOutline}
                      value={searchText}
                      onIonChange={(e) => handleSearch(e.detail.value!)}
                      animated
                      placeholder="search investors..."
                    ></IonSearchbar>
                    <IonList className="investor-list">
                      {items
                        ? items.map((item, key) => (
                            <IonItem routerLink={"/investor/" + item[0]}>
                              {item[1]}
                            </IonItem>
                          ))
                        : null}
                    </IonList>
                  </div>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal
          isOpen={createUserModal}
          onDidDismiss={() => setCreateUserModal(false)}
        >
          <div className="modal-wrapper">
            <IonList>
              <h3>Create Investor</h3>
              <br />
              <IonItem>
                <IonLabel position="floating">First Name</IonLabel>
                <IonInput
                  value={firstName}
                  onIonChange={(e: any) => setFirstName(e.target.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Last Name</IonLabel>
                <IonInput
                  value={lastName}
                  onIonChange={(e: any) => setLastName(e.target.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Email Name</IonLabel>
                <IonInput
                  value={email}
                  onIonChange={(e: any) => setEmail(e.target.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">PAN</IonLabel>
                <IonInput
                  value={pan}
                  onIonChange={(e: any) => setPAN(e.target.value)}
                  required
                />
              </IonItem>
              <p />
              <Alert alert={alert} />
            </IonList>
            <IonButton onClick={handleCreateInvestor}>Create</IonButton>
            <IonButton onClick={() => setCreateUserModal(false)}>
              Cancel
            </IonButton>
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Investors;
