import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  IonLabel,
  IonItem,
  IonCardContent,
  IonMenuButton,
  IonButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonBadge,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
} from "@ionic/react";
import { Line } from "react-chartjs-2";
import { flaskSharp, refreshOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { podiumOutline } from "ionicons/icons";
import { useParams } from "react-router";
import Widget from "../components/Widget";
import Table from "../components/Table";
import "../theme/styles.css";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import API from "../api";
import {
  submittedApplicationsHeader,
  recentApplicationsHeader,
  getApplicationStatus,
  getWorkflowStatus,
  getAssignedUser,
} from "../services/ApplicationServices";

const Applications: React.FC = () => {
  const { name } = useParams<{ name: string }>();

  const [applicationRecords, setApplicationRecords] = useState([]);
  const [selectedTab, setSelectedTab] = useState("0");

  const data = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };

  var options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  const getSubmittedApplications = (records: any) => {
    let _records: any = [];
    if (records.length > 0) {
      records.map((record: any, key: any) => {
        if (record.applicationStatus == 5 && record.workflowStatus < 30) {
          _records.push([
            <Link key={key} to={"/application/" + record.applicationId}>
              {record.applicationId}
            </Link>,
            record.firstName + " " + record.lastName,
            record.communicationNumber,
            <IonBadge className="text-xs" color="light">
              {getAssignedUser(record.assignedTo)}
            </IonBadge>,
            record.verifiedAt.substr(0, 10),
            <IonBadge className="text-xs" color="warning">
              {getWorkflowStatus(record.workflowStatus)}
            </IonBadge>,
          ]);
        }
      });
    }
    return _records;
  };

  const getKYCApplications = (records: any) => {
    let _records: any = [];
    if (records.length > 0) {
      records.map((record: any, key: any) => {
        if (record.workflowStatus >= 31 && record.workflowStatus < 40) {
          _records.push([
            <Link key={key} to={"/application/" + record.applicationId}>
              {record.applicationId}
            </Link>,
            record.firstName + " " + record.lastName,
            record.communicationNumber,
            <IonBadge className="text-xs" color="light">
              {getAssignedUser(record.assignedTo)}
            </IonBadge>,
            record.verifiedAt.substr(0, 10),
            <IonBadge className="text-xs" color="warning">
              {getWorkflowStatus(record.workflowStatus)}
            </IonBadge>,
          ]);
        }
      });
    }
    return _records;
  };

  const getRegisteredApplications = (records: any) => {
    let _records: any = [];
    if (records.length > 0) {
      records.map((record: any, key: any) => {
        if (record.workflowStatus >= 41 && record.workflowStatus < 50) {
          _records.push([
            <Link key={key} to={"/application/" + record.applicationId}>
              {record.applicationId}
            </Link>,
            record.firstName + " " + record.lastName,
            record.communicationNumber,
            <IonBadge className="text-xs" color="light">
              {getAssignedUser(record.assignedTo)}
            </IonBadge>,
            record.verifiedAt.substr(0, 10),
            <IonBadge className="text-xs" color="warning">
              {getWorkflowStatus(record.workflowStatus)}
            </IonBadge>,
          ]);
        }
      });
    }
    return _records;
  };

  const getClosedApplications = (records: any) => {
    let _records: any = [];
    if (records.length > 0) {
      records.map((record: any, key: any) => {
        if (record.workflowStatus == 98) {
          _records.push([
            <Link key={key} to={"/application/" + record.applicationId}>
              {record.applicationId}
            </Link>,
            record.firstName + " " + record.lastName,
            record.communicationNumber,
            <IonBadge className="text-xs" color="light">
              {getAssignedUser(record.assignedTo)}
            </IonBadge>,
            record.verifiedAt.substr(0, 10),
            <IonBadge className="text-xs" color="warning">
              {getWorkflowStatus(record.workflowStatus)}
            </IonBadge>,
          ]);
        }
      });
    }
    return _records;
  };

  const getCompletedApplications = (records: any) => {
    let _records: any = [];
    if (records.length > 0) {
      records.map((record: any, key: any) => {
        if (record.workflowStatus == 99) {
          _records.push([
            <Link key={key} to={"/application/" + record.applicationId}>
              {record.applicationId}
            </Link>,
            record.firstName + " " + record.lastName,
            record.communicationNumber,
            <IonBadge className="text-xs" color="light">
              {getAssignedUser(record.assignedTo)}
            </IonBadge>,
            record.verifiedAt.substr(0, 10),
            <IonBadge className="text-xs" color="warning">
              {getWorkflowStatus(record.workflowStatus)}
            </IonBadge>,
          ]);
        }
      });
    }
    return _records;
  };

  const getRecentApplications = (records: any) => {
    let _records: any = [];
    if (records.length > 0) {
      records.map((record: any, key: any) => {
        if (record.applicationStatus > 0 && record.applicationStatus != 5) {
          _records.push([
            <Link key={key} to={"/application/" + record.applicationId}>
              {record.applicationId}
            </Link>,
            record.firstName + " " + record.lastName,
            record.email,
            record.communicationNumber,
            record.verifiedAt.substr(0, 10),
            <IonBadge className="text-xs" color="warning">
              {getApplicationStatus(record.applicationStatus)}
            </IonBadge>,
          ]);
        }
      });
    }
    return _records;
  };

  const fetchApplications = () => {
    API.getApplications()
      .then((result) => {
        setApplicationRecords(result.data.data);
      })
      .catch((error) => {});
  };

  const handleRefresh = () => {
    fetchApplications();
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const showTabContent = (tabOption: any) => {
    if (tabOption == "0") {
      return (
        <Table
          header={recentApplicationsHeader}
          records={getRecentApplications(applicationRecords)}
        />
      );
    }
    if (tabOption == "1") {
      return (
        <Table
          header={submittedApplicationsHeader}
          records={getSubmittedApplications(applicationRecords)}
        />
      );
    }
    if (tabOption == "2") {
      return (
        <Table
          header={submittedApplicationsHeader}
          records={getKYCApplications(applicationRecords)}
        />
      );
    }
    if (tabOption == "3") {
      return (
        <Table
          header={submittedApplicationsHeader}
          records={getRegisteredApplications(applicationRecords)}
        />
      );
    }
    if (tabOption == "4") {
      return (
        <Table
          header={submittedApplicationsHeader}
          records={getCompletedApplications(applicationRecords)}
        />
      );
    }

    if (tabOption == "5") {
      return (
        <Table
          header={submittedApplicationsHeader}
          records={getClosedApplications(applicationRecords)}
        />
      );
    }
  };

  return (
    <IonPage>
      <Header title="Applications" />

      <IonContent fullscreen class="ion-padding app-bg">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton onClick={handleRefresh}>
                Refresh
                <IonIcon slot="start" size="small" icon={refreshOutline} />
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <Widget
              data={
                <>
                  <IonSegment
                    value={selectedTab}
                    onIonChange={(e) => setSelectedTab(e.detail.value!)}
                  >
                    <IonSegmentButton mode="md" value="0">
                      <IonLabel>Leads</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton mode="md" value="1">
                      <IonLabel>Submitted</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton mode="md" value="2">
                      <IonLabel>KYC</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton mode="md" value="3">
                      <IonLabel>Registration</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton mode="md" value="4">
                      <IonLabel>Completed</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton mode="md" value="5">
                      <IonLabel>Closed</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                  <br />
                  {showTabContent(selectedTab)}
                </>
              }
            />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Applications;
