import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBadge,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";

import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router";
import API from "../api";
import Widget from "../components/Widget";
import Table from "../components/Table";
import StatusWidget from "../components/StatusWidget";

const Exload: React.FC = () => {
  const header = ["Report #", "Name", "Frequency","Last Executed", "Status"];
  const [reports, setReports] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any[]>([
    {
      total_reports: 0,
      failed_reports: 0,
      success_reports: 0,
      report_types: 0,
    },
  ]);

  useEffect(() => {
    setLoading(true);
    API.getAllReports()
      .then((result) => {
        setLoading(false);
        var _reports: any = [];
        var _success_counter = 0;
        result.data.data.map((report: any, key: any) => {
          var _status: any;
          if (report.status == "0") {
            _success_counter++;
            _status = (
              <IonBadge className="text-xs" color="success">
                Success
              </IonBadge>
            );
          } else {
            _status = (
              <IonBadge className="text-xs" color="danger">
                Failed
              </IonBadge>
            );
          }
          _reports.push([
            report.reportId,
            report.reportName,
            report.frequency,
            report.startedAt,
            _status,
          ]);
        });
        setReports(_reports);
        setSummary([
          {
            total_reports: _reports.length,
            failed_reports: _reports.length - _success_counter,
            success_reports: _success_counter,
            report_types: 0,
          },
        ]);
      })
      .catch((error) => {
        console.log(error)
        console.log("Failed to retrieve the reports");
      });
  }, []);

  const showReports = () => {
    return (
      <Widget
        title="Kashly Reports"
        data={<Table header={header} records={reports} loading={loading} />}
      />
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Exload</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen class="ion-padding app-bg">
        <IonGrid>
          <IonRow>
            <IonCol size="6" size-lg="3">
              <StatusWidget
                title="Total Reports"
                content={summary[0].total_reports}
              />
            </IonCol>
            <IonCol size="6" size-lg="3">
              <StatusWidget
                title="Failed"
                content={summary[0].failed_reports}
              />
            </IonCol>
            <IonCol size="6" size-lg="3">
              <StatusWidget
                title="Completed"
                content={summary[0].success_reports}
              />
            </IonCol>
            <IonCol size="6" size-lg="3">
              <StatusWidget
                title="Report Types"
                content={summary[0].report_types}
              />
            </IonCol>
          </IonRow>
          <IonRow>{showReports()}</IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Exload;
