import {
  IonRow,
  IonCol,
  IonSkeletonText,
  IonLabel,
  IonAvatar,
  IonText,
} from "@ionic/react";
import { currencyFormat } from "../Utilities";
interface HoldingProps {
  summary?: any;
  loading?: boolean;
}
const HoldingSummary: React.FC<HoldingProps> = ({ summary, loading }) => {
  if (loading) {
    return (
      <div className="investment-overview-card">
        <br />
        <IonRow>
          <IonCol>
            <IonSkeletonText
              animated
              style={{ width: "80%", height: "20px" }}
            />
          </IonCol>
          <IonCol>
            <IonSkeletonText
              animated
              style={{ width: "80%", height: "20px" }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
          <IonSkeletonText
              animated
              style={{ width: "50%", height: "10px" }}
            />
          </IonCol>
          <IonCol>
          <IonSkeletonText
              animated
              style={{ width: "50%", height: "10px" }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonSkeletonText
              animated
              style={{ width: "80%", height: "20px" }}
            />
          </IonCol>
          <IonCol>
            <IonSkeletonText
              animated
              style={{ width: "80%", height: "20px" }}
            />
          </IonCol>
        </IonRow>
      </div>
    );
  } else {
    return (
      <div className="investment-overview-card">
        <br />
        <IonRow>
          <IonCol>
            {summary.abs_pnl >= 0 ? (
              <IonText color="success">
                <b>{currencyFormat(summary.abs_pnl)}</b>
              </IonText>
            ) : (
              <IonText color="danger">
                <b>{currencyFormat(summary.abs_pnl)}</b>
              </IonText>
            )}
          </IonCol>
          <IonCol>
            {summary.abs_pnl_percent >= 0 ? (
              <IonText color="success">
                <b>+{summary.abs_pnl_percent}%</b>
              </IonText>
            ) : (
              <IonText color="danger">
                <b>{summary.abs_pnl_percent}%</b>
              </IonText>
            )}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <span className="text-xs">Invested</span>
          </IonCol>
          <IonCol>
            <span className="text-xs">Current</span>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <span className="text-s">
              <b>{currencyFormat(summary.invested_amount)}</b>
            </span>
          </IonCol>
          <IonCol>
            <span className="text-s">
              <b>{currencyFormat(summary.current_value)}</b>
            </span>
          </IonCol>
        </IonRow>
      </div>
    );
  }
};
export default HoldingSummary;
