import * as actions from "../actions/actions";

const initialState = {
  list: null
};

const storeAllInvestors = (state: any, action: any) => {
  return {
    ...state,
    list: action.data
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.STORE_ALL_INVESTORS:
      return storeAllInvestors(state, action);
    default:
      return state;
  }
};

export default reducer;
