import * as actions from "./actions";
import API from "../../api";

export const storeSIPDues = (data: any) => {
  return {
    type: actions.STORE_SIP_DUES,
    data: data,
  };
};

export const getAllSIPDues= () => {
  return function (dispatch: any) {
    API.getSIPDues()
      .then((result) => {
        dispatch(storeSIPDues(result.data.data));
      })
      .catch((error) => {
        console.log("Failed to retrieve SIP dues");
        console.log(error.response.status)
      });
  };
};

export const storeCancelledSIPs = (data: any) => {
  return {
    type: actions.STORE_CANCELLED_SIPS,
    data: data,
  };
};

export const getCancelledSIPs= (startDate:string,endDate:string) => {
  return function (dispatch: any) {
    API.getCancelledSIPs(startDate,endDate)
      .then((result) => {
        dispatch(storeCancelledSIPs(result.data.data));
      })
      .catch((error) => {
        console.log("Failed to retrieve cancelled dues");
      });
  };
};

export const storePendingSIPs = (data: any) => {
  return {
    type: actions.STORE_PENDING_SIPS,
    data: data,
  };
};

export const getPendingSIPs= (startDate:string,endDate:string) => {
  return function (dispatch: any) {
    API.getPendingSIPs(startDate,endDate)
      .then((result) => {
        dispatch(storePendingSIPs(result.data.data));
      })
      .catch((error) => {
        console.log("Failed to retrieve pending dues");
      });
  };
};

