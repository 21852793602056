import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonText,
} from "@ionic/react";

import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { useLocation } from "react-router-dom";
import {
  cashOutline,
  documentTextOutline,
  folderOpenOutline,
  homeOutline,
  optionsOutline,
  peopleOutline,
  powerOutline,
  repeatOutline,
  scaleOutline,
} from "ionicons/icons";

interface AppPage {
  title: string;
  url: string;
  icon: string;
}

const appPages: AppPage[] = [
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: homeOutline,
  },
  {
    title: "Applications",
    url: "/applications",
    icon: documentTextOutline,
  },
  {
    title: "SIP Tracker",
    url: "/siptracker",
    icon: scaleOutline,
  },
  {
    title: "Investors",
    url: "/investors",
    icon: peopleOutline,
  },
  {
    title: "Transact",
    url: "/transact",
    icon: repeatOutline,
  },
  {
    title: "Exload",
    url: "/exload",
    icon: folderOpenOutline,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  const userState: any = useSelector<RootState>((state) => state.user);
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            <IonText color="primary">Konsole</IonText>
          </IonListHeader>
          <IonNote>
            Welcome, {userState.name != "" ? userState.name : ""}
          </IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.icon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        <IonList>
          <IonItem
            routerLink="/logout"
            routerDirection="none"
            lines="none"
            detail={false}
          >
            <IonIcon slot="start" icon={powerOutline} />
            <IonLabel>Signout</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
