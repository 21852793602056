import axios from "axios";

const basicAuth =
  "Basic " +
  btoa(
    process.env.REACT_APP_SECRET_NAME + ":" + process.env.REACT_APP_SECRET_CODE
  );

const authRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    Authorization: basicAuth,
  },
});

/* Resource Server Requests */
const resourceRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    "content-type": "application/json",
  },
});

resourceRequest.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const patchRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    "content-type": "application/json-patch+json",
  },
});

export default {
  userLogin: (data) =>
    authRequest({
      method: "POST",
      url: "/oauth/token",
      data: new URLSearchParams(data),
    }),
  getUser: (id) =>
    resourceRequest({
      method: "GET",
      url: "/v1/user/" + id,
    }),
  createUser: (data) =>
    resourceRequest({
      method: "POST",
      url: "/v1/user/",
      data: data,
    }),
  getAllInvestors: () =>
    resourceRequest({
      method: "GET",
      url: "/v1/user?role=ROLE_INVESTOR",
    }),

  getTransactions: (id, start_date, end_date) =>
    resourceRequest({
      method: "GET",
      url:
        "/v1/transactions/" +
        id +
        "/range?start=" +
        start_date +
        "&end=" +
        end_date,
    }),
  getSIPDues: () =>
    resourceRequest({
      method: "GET",
      url: "/v1/sips/transactions?status=PROCESSING",
    }),
  getPendingSIPs: (startDate, endDate) =>
    resourceRequest({
      method: "GET",
      url: "/v1/sips?status=PENDING&start=" + startDate + "&end=" + endDate,
    }),
  getActiveSIPs: (id) =>
    resourceRequest({
      method: "GET",
      url: "/v1/sips/" + id + "?status=ACTIVE",
    }),
  getCancelledSIPs: (startDate, endDate) =>
    resourceRequest({
      method: "GET",
      url: "/v1/sips?status=CANCELLED&start=" + startDate + "&end=" + endDate,
    }),
  getHoldings: (id) =>
    resourceRequest({
      method: "GET",
      url: "/v2/holdings/" + id,
    }),

  getAllReports: () =>
    resourceRequest({
      method: "GET",
      url: "/reports/all",
    }),

  getApplication: (id) =>
    resourceRequest({
      method: "GET",
      url: "v1/applications/operations/id/" + id,
    }),

  updateApplication: (id, data) =>
    patchRequest({
      method: "PATCH",
      url: "/v1/applications/" + id,
      data: data,
    }),

  getApplications: () =>
    resourceRequest({
      method: "GET",
      url: "/v1/applications/operations/all",
    }),

  createNote: (data) =>
    resourceRequest({
      method: "POST",
      url: "/v1/notes/",
      data: data,
    }),

    getNotes: (id,type) =>
    resourceRequest({
      method: "GET",
      url: "/v1/notes/find?type=" + type + "&typeId=" + id
    }),
};
