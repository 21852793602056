import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import React, { useState, useContext, useEffect } from "react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { Redirect } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/styles.css";

import Login from "./pages/Login";
import Logout from "./pages/Logout";

interface IUserManager {
  setIsLoggedIn: Function;
}

const user: IUserManager = {
  setIsLoggedIn: () => {},
};

export const UserContext = React.createContext<IUserManager>(user);

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = useContext(UserContext);
  user.setIsLoggedIn = setIsLoggedIn;

  return (
    <IonApp>
      <UserContext.Provider value={user}>
        <IonReactRouter>
          <Route path="/" component={isLoggedIn ? AppRouter : Login} />
          <Route path="/login" component={Login} exact={true} />
          <Route path="/logout" component={Logout} exact={true} />
        </IonReactRouter>
      </UserContext.Provider>
    </IonApp>
  );
};

export default App;
