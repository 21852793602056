import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonToast,
  IonProgressBar,
  IonButton,
  IonPage,
  IonRow,
  IonInput,
  IonChip,
  IonText,
} from "@ionic/react";
import {
  mailOutline,
  callOutline,
  logoWhatsapp,
  checkmarkCircle,
  alertCircle,
} from "ionicons/icons";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import Header from "../components/Header";
import API from "../api";
import {
  getOccupationName,
  getTaxStatus,
  getAccountType,
  getCountryList,
} from "../services/ApplicationServices";

import { applicationPDF } from "../services/ReportServices";

const Application: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState({
    isOpen: false,
    color: "success",
    message: "",
    duration: 2000,
  });
  const [appNote, setAppNote] = useState({
    type: "APP",
    typeId: "",
    note: "",
  });

  const [notes, setNotes] = useState([
    {
      id: 0,
      type: "APP",
      typeId: "",
      note: "",
      createdBy: "",
      modifiedBy: "",
      createdAt: "",
      modifiedAt: "",
    },
  ]);

  const [application, setApplication] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    communicationNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    gender: "",
    dob: "",
    nationality: "",
    birthCountry: "",
    birthCity: "",
    fatherName: "",
    spouseName: "",
    occupation: "",
    annualIncome: "",
    nomineeName: "",
    nomineeDob: "",
    nomineeRelation: "",
    pan: "",
    accountNumber: "",
    accountType: "",
    taxStatus: "",
    taxCountry: "",
    micr: "",
    ifsc: "",
    kraName: "",
    kycStatus: "",
    lastUpdated: "",
    assignedTo: "",
    workflowStatus: "",
  });

  const getApplicationDetails = (appData: any) => {
    let _application: any = {};
    if (appData != null) {
      _application = {
        firstName: appData.firstName,
        lastName: appData.lastName,
        email: appData.email,
        contact: appData.contactNumber,
        communicationNumber: appData.communicationNumber,
        address1: appData.addressLine1,
        address2: appData.addressLine2,
        city: appData.city,
        state: appData.state,
        country: appData.country,
        zip: appData.zip,
        gender: appData.gender,
        dob: appData.dob,
        nationality: appData.nationality != "" ? appData.nationality : "India",
        birthCountry:
          appData.birthCountry != "" ? appData.birthCountry : "India",
        birthCity: appData.birthCity,
        fatherName: appData.fatherName,
        spouseName: appData.spouseName,
        occupation: getOccupationName(appData.occupation),
        annualIncome: appData.annualIncome,
        nomineeName: appData.nomineeName,
        nomineeDob: appData.nomineeDob,
        nomineeRelation: appData.nomineeRelation,
        pan: appData.pan,
        accountNumber: appData.accountNumber,
        accountType: getAccountType(appData.accountType),
        taxStatus: getTaxStatus(appData.taxStatus),
        taxCountry: appData.taxCountry != "" ? appData.taxCountry : "India",
        micr: appData.micr != null ? appData.micr : "",
        ifsc: appData.ifscCode,
        kraName: appData.kraName,
        kycStatus: appData.kycStatus,
        lastUpdated: appData.verifiedAt,
        assignedTo: appData.assignedTo,
        workflowStatus: appData.workflowStatus,
      };
    }
    return _application;
  };

  function updateApplication(id: any, formData: any) {
    setIsLoading(true);
    API.updateApplication(id, formData)
      .then((result) => {
        if (result.data.status === "success") {
          setToast({
            ...toast,
            isOpen: true,
            message: "Application Updated Successfully!",
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setToast({
          ...toast,
          isOpen: true,
          color: "danger",
          message: "Something went wrong! Please try again later.",
        });
        setIsLoading(false);
      });
  }

  function handleUpdate() {
    let formData = [
      {
        op: "replace",
        path: "/workflowStatus",
        value: application.workflowStatus,
      },
      { op: "replace", path: "/assignedTo", value: application.assignedTo },
    ];
    updateApplication(id, formData);
  }

  const addNote = () => {
    if (appNote.note != "") {
      setIsLoading(true);
      API.createNote(appNote)
        .then((result) => {
          if (result.data.status === "success") {
            getApplicationNotes(id);
            setToast({
              ...toast,
              isOpen: true,
              message: "Note Added Successfully!",
            });
            setAppNote({ type: "APP", typeId: "", note: "" });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setToast({
            ...toast,
            isOpen: true,
            color: "danger",
            message: "Something went wrong! please try later.",
          });
          setAppNote({ type: "APP", typeId: "", note: "" });
          setIsLoading(false);
        });
    }
  };

  const getApplicationNotes = (id: any) => {
    API.getNotes(id, "APP")
      .then((result) => {
        if (result.data.status === "success" && result.data.data.length > 0) {
          setNotes(result.data.data);
        }
      })
      .catch((error) => {});
  };

  const getApplication = () => {
    API.getApplication(id)
      .then((result) => {
        setApplication(getApplicationDetails(result.data.data));
      })
      .catch((error) => {});
  };

  const downloadAOF = () => {
    applicationPDF(application);
  };

  useEffect(() => {
    setNotes([]);
    getApplication();
    getApplicationNotes(id);
  }, [id]);

  const ApplicationForm = () => {
    return (
      <>
        <div className="contentWrapper">
          <IonRow>
            <IonCol size="3">
              <img className="applicationAvatar" src="assets/images/user.png" />
            </IonCol>
            <IonCol>
              <IonText color="primary">
                <h2>{application.firstName + " " + application.lastName}</h2>
              </IonText>
              <IonChip>
                <IonIcon size="small" icon={mailOutline} color="dark" />
                <IonLabel>{application.email}</IonLabel>
              </IonChip>
              {application.kycStatus == "Y" ? (
                <IonChip color="primary">
                  <IonIcon
                    size="small"
                    icon={checkmarkCircle}
                    color="primary"
                  />
                  <IonLabel>Complete KYC</IonLabel>
                </IonChip>
              ) : (
                <IonChip color="danger">
                  <IonIcon size="small" icon={alertCircle} color="danger" />
                  <IonLabel>Incomplete KYC</IonLabel>
                </IonChip>
              )}
            </IonCol>
          </IonRow>
        </div>
        <div className="contentWrapper">
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Address Line 1</IonLabel>
                <IonInput
                  value={application.address1}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      address1: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Address Line 2</IonLabel>
                <IonInput
                  value={application.address2}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      address2: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">City</IonLabel>
                <IonInput
                  value={application.city}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      city: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">State</IonLabel>
                <IonInput
                  value={application.state}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      state: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Country</IonLabel>
                <IonInput
                  value={application.country}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      country: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Zip</IonLabel>
                <IonInput
                  value={application.zip}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      zip: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Contact Number</IonLabel>
                <IonInput
                  value={application.contact}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      contact: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Communication Number</IonLabel>
                <IonInput
                  value={
                    application.communicationNumber != ""
                      ? application.communicationNumber
                      : "Not Provided"
                  }
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      communicationNumber: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
        </div>
        <div className="contentWrapper">
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Gender</IonLabel>
                <select
                  className="form-select"
                  value={application.gender != null ? application.gender : ""}
                  onChange={(e) =>
                    setApplication({
                      ...application,
                      gender: e.target.value!,
                    })
                  }
                >
                  <option value="">Not Filled</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Date of Birth</IonLabel>
                <IonInput
                  value={application.dob}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      dob: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Nationality</IonLabel>
                <select
                  className="form-select"
                  value={
                    application.nationality != null
                      ? application.nationality
                      : ""
                  }
                  onChange={(e) =>
                    setApplication({
                      ...application,
                      nationality: e.target.value!,
                    })
                  }
                >
                  {getCountryList()}
                </select>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Country Of Birth</IonLabel>
                <select
                  className="form-select"
                  value={
                    application.birthCountry != null
                      ? application.birthCountry
                      : ""
                  }
                  onChange={(e) =>
                    setApplication({
                      ...application,
                      birthCountry: e.target.value!,
                    })
                  }
                >
                  {getCountryList()}
                </select>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">City/Place Of Birth</IonLabel>
                <IonInput
                  value={application.birthCity}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      birthCity: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Father's Name</IonLabel>
                <IonInput
                  value={application.fatherName}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      fatherName: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Spouse's Name</IonLabel>
                <IonInput
                  value={application.spouseName}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      spouseName: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Occupation</IonLabel>
                <IonInput
                  value={application.occupation}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      occupation: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Gross Annual Income</IonLabel>
                <IonInput
                  value={application.annualIncome}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      annualIncome: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
        </div>
        <div className="contentWrapper">
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Nominee Name</IonLabel>
                <IonInput
                  value={application.nomineeName}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      nomineeName: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Nominee Date of Birth</IonLabel>
                <IonInput
                  value={application.nomineeDob}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      nomineeDob: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Nominee Relation</IonLabel>
                <select
                  className="form-select"
                  value={
                    application.nomineeRelation != null
                      ? application.nomineeRelation
                      : ""
                  }
                  onChange={(e) =>
                    setApplication({
                      ...application,
                      nomineeRelation: e.target.value!,
                    })
                  }
                >
                  <option value="">Not Filled</option>
                  <option value="Wife">Wife</option>
                  <option value="Husband">Husband</option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Sister">Sister</option>
                  <option value="Brother">Brother</option>
                  <option value="Son">Son</option>
                  <option value="Daughter">Daughter</option>
                </select>
              </IonItem>
            </IonCol>
          </IonRow>
        </div>
        <div className="contentWrapper">
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Account Type</IonLabel>
                <IonInput
                  value={application.accountType}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      accountType: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Account Number</IonLabel>
                <IonInput
                  value={application.accountNumber}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      accountNumber: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">IFSC Code</IonLabel>
                <IonInput
                  value={application.ifsc}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      ifsc: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">MICR Code</IonLabel>
                <IonInput
                  value={application.micr}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      micr: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">PAN Card</IonLabel>
                <IonInput
                  value={application.pan}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      pan: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Tax Status</IonLabel>
                <IonInput
                  value={application.taxStatus}
                  placeholder="Not Filled"
                  onIonChange={(e) =>
                    setApplication({
                      ...application,
                      taxStatus: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="form-item" lines="none">
                <IonLabel position="stacked">Country Of Tax Residency</IonLabel>
                <select
                  className="form-select"
                  value={
                    application.taxCountry != null
                      ? application.taxCountry
                      : "India"
                  }
                  onChange={(e) =>
                    setApplication({
                      ...application,
                      taxCountry: e.target.value!,
                    })
                  }
                >
                  {getCountryList()}
                </select>
              </IonItem>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
        </div>
      </>
    );
  };

  const sideBar = () => {
    return (
      <>
        <div className="contentWrapper">
          <IonItem className="form-item" lines="none">
            <IonText>
              <h4>Application Status</h4>
            </IonText>
            <br />
            <IonLabel position="stacked">Assigned to</IonLabel>
            <select
              className="form-select"
              value={
                application.assignedTo != null ? application.assignedTo : ""
              }
              onChange={(e) =>
                setApplication({
                  ...application,
                  assignedTo: e.target.value!,
                })
              }
            >
              <option value="">Not Assigned</option>
              <option value="KA0003">Saranya S Nair</option>
              <option value="KA0002">Vishnu M Nair</option>
              <option value="KA0005">Rishal Mathew</option>
              <option value="KA0001">Alex Jacob</option>
            </select>
          </IonItem>
          <IonItem className="form-item" lines="none">
            <IonLabel position="stacked">Workflow</IonLabel>
            <select
              className="form-select"
              value={
                application.workflowStatus != null
                  ? application.workflowStatus
                  : ""
              }
              onChange={(e) =>
                setApplication({
                  ...application,
                  workflowStatus: e.target.value!,
                })
              }
            >
              <option value="">No Status</option>
              <option value="11">Initial Call</option>
              <option value="12">Second Call</option>
              <option value="13">Third Call</option>
              <option value="14">Not Interested</option>
              <option value="15">No Response</option>
              <option value="21">Payment Requested</option>
              <option value="22">Payment Completed</option>
              <option value="31">Document Collection</option>
              <option value="32">KYC Initiated</option>
              <option value="33">KYC In Progress</option>
              <option value="34">KYC Rejected</option>
              <option value="35">KYC Resubmitted</option>
              <option value="36">KYC Completed</option>
              <option value="41">SIP Initiated</option>
              <option value="42">SIP Rejected</option>
              <option value="43">SIP Resubmitted</option>
              <option value="44">SIP Registered</option>
              <option value="97">Archived</option>
              <option value="98">Closed</option>
              <option value="99">Completed</option>
            </select>
          </IonItem>
          <br />
          <IonItem className="form-item" lines="none">
            <IonLabel>
              <span className="text-xs">
                Last updated on {application.lastUpdated}
              </span>
            </IonLabel>
          </IonItem>
          <IonItem className="form-item" lines="none">
            <IonButton size="default" onClick={handleUpdate}>
              Update
            </IonButton>
            <IonButton size="default" onClick={downloadAOF}>
              Download
            </IonButton>
          </IonItem>
        </div>
        {isLoading ? (
          <IonProgressBar type="indeterminate"></IonProgressBar>
        ) : null}

        <div className="contentWrapper">
          <IonItem className="form-item" lines="none">
            <IonText>
              <h4>Notes</h4>
            </IonText>
          </IonItem>
          <IonItem lines="none">
            <textarea
              value={appNote.note}
              className="text-area"
              onChange={(e) =>
                setAppNote({
                  ...appNote,
                  typeId: id,
                  note: e.target.value,
                })
              }
            ></textarea>
          </IonItem>
          <IonItem lines="none">
            <IonButton size="default" onClick={addNote}>
              Add Note
            </IonButton>
          </IonItem>
          {notes != null
            ? notes.map((note: any, key: any) => {
                return (
                  <div id={key} className="application-note-entry">
                    <p className="application-note-text">{note.note}</p>
                    <p className="application-note-audit">
                      {note.createdBy} on {note.createdAt}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </>
    );
  };

  return (
    <IonPage>
      <Header title={"Kashly Application - " + id} />
      <IonContent fullscreen class="ion-padding app-bg">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-lg="8">
              {ApplicationForm()}
            </IonCol>
            <IonCol size="12" size-lg="4">
              {sideBar()}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonToast
        isOpen={toast.isOpen}
        color={toast.color}
        onDidDismiss={() =>
          setToast({
            ...toast,
            isOpen: false,
          })
        }
        message={toast.message}
        duration={toast.duration}
      />
    </IonPage>
  );
};

export default Application;
